@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

$font-family: 'Source Sans Pro', sans-serif;

// colors
$primary: #00e1be;
$white: #ffffff;
$black: #000000;
$text-dark: #1d1d1b;

body {
  margin: 0px;
  font-family: $font-family;
}

p {
  font-size: 1.2rem;
}

//helper classes
// color helpers
.brand-color {
  color: $primary;
}

// button styles
// default-btn
.btn-default {
  background-color: $primary;
  color: $black;
  height: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid $primary;
  border-radius: 8px;
  transition: ease 0.1s;
  cursor: pointer;
  padding: 0 30px;
  text-decoration: none;
  white-space: nowrap;
  &:hover,
  &:focus {
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    background-color: #03d0b0;
    border-color: #03d0b0;
    color: $black;
  }
  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
  }
  &:disabled,
  &[disabled] {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
    opacity: 0.8;
    cursor: default;
  }
}
.btn-outline {
  background-color: transparent;
  color: $black;
  height: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid $black;
  border-radius: 8px;
  padding: 0 40px;
  transition: ease 0.1s;
  cursor: pointer;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.13);
  }
  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
  }
  &:disabled,
  &[disabled] {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    background-color: $black;
    border-color: $black;
    color: $white;
    opacity: 0.8;
    cursor: default;
  }
  &.icon-left-btn {
    height: 34px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
    border-radius: 4px;
    border-width: 1px;
    transition: ease 0.1s;
    cursor: pointer;
    img {
      height: 14px;
      margin-right: 8px;
    }
    &:hover,
    &:focus {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  &.icon-right-btn {
    height: 34px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
    border-radius: 4px;
    border-width: 1px;
    transition: ease 0.1s;
    cursor: pointer;
    img {
      height: 14px;
      margin-left: 8px;
    }
    &:hover,
    &:focus {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.btn-share {
  border: none;
  background-color: rgba(29, 29, 27, 0.3);
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  height: 31px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  img {
    height: 10px;
    margin-right: 8px;
  }
  &:hover {
    transform: translateY(-2px);
    background-color: $black;
  }
  &:active {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.17);
  }
}

// switch styles
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

// small container style
.container {
  &.small-container {
    max-width: 800px;
  }
}

// Home styles------------------------------------
.authentication {
  position: relative;
  color: white;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/images/login-bg.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  .authentication-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;

    .welcome {
      padding-top: 30px;
      padding-bottom: 15px;
      .welcome-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .logo {
          width: 100px;
          margin-bottom: 40px;
        }
        //claim todo
        .vendor-logo {
          width: auto;
          height: 90px;
          margin-bottom: 37px;
          border: 2px solid $primary;
          padding: 2px;
        }
        //claim todo
        .locked-away-text {
          font-size: 20px;
          margin-bottom: 0px;
          &.text-left {
            text-align: left;
            margin-bottom: 20px;
          }
        }
        .amount-text {
          font-size: 22px;
          margin-bottom: 6px;
          b {
            font-weight: bold;
          }
        }
        .vendor-text {
          font-size: 16px;
          margin-bottom: 47px;
        }
        .saying-text {
          font-size: 15px;
          color: $primary;
          max-width: 240px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
        }
        h2 {
          font-size: 36px;
          line-height: 1.5;
        }
        .data {
          table {
            width: 100%;
            max-width: 300px;
            font-size: 18px;
            tr {
              td {
                text-align: left;
                min-width: 100px;
                &:first-child {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    .signin {
      color: black;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      background-color: $white;
      margin: 0 -0.75rem;
      width: calc(100% + 1.5rem);

      .signin-content {
        padding: 47px 16px 30px 16px;
        .keep-track-text {
          margin-bottom: 36px;
          font-size: 20px;
          color: $text-dark;
          font-weight: bold;
        }
        .learn-more-text {
          margin-top: 42px;
          font-size: 16px;
          a {
            font-weight: bold;
            color: $black;
          }
        }
        .button-wrapper {
          display: flex;
          flex-direction: column;
          button,
          a {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .authentication-wrapper {
      justify-content: center;
      .welcome {
        .welcome-content {
          .logo {
          }
        }
      }
      .signin {
        border-radius: 16px;
        max-width: 500px;
        margin-bottom: 30px;
        .signin-content {
          .button-wrapper {
            flex-direction: row;
            width: 100%;
            button,
            a {
              margin: 0 10px;
              width: 50%;
            }
          }
        }
      }
    }
  }
  &.claim {
    .authentication-wrapper {
      .welcome {
        padding-top: 30px;
        .welcome-content {
          .logo {
            height: 44px;
            width: auto;
            margin-bottom: 60px;
          }
          .vendor-logo {
            height: 100px;
            width: auto;
            margin-bottom: 20px;
            background-color: lightgray;
            border: 1px solid lightgray;
            border-radius: 10px;
          }
          .locked-away-text {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
          }
          .amount-text {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 6px;
            b {
              font-size: 22px;
              font-weight: 900;
            }
          }
          .vendor-text {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 60px;
          }
          .saying-text {
            font-size: 18px;
            color: $primary;
            max-width: 310px;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .claim-carbon {
          h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 22px;
            margin-top: 40px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// homepage dashboard styles
.home-page-dashboard {
  background-color: $primary;
  min-height: 100vh;
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (min-width: 1024px) {
    background-color: $white;
    padding-top: 84px;
    padding-bottom: 30px;
  }
}

.home-nav {
  .home-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 10px;
    .logo {
      height: 34px;
    }
  }
  @media only screen and (min-width: 1024px) {
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .logo {
      filter: invert(1);
    }
    .btn-outline {
      &.icon-left-btn {
        border-color: $white;
        color: $white;
        img {
          filter: invert(1);
        }
        &:hover {
          border-color: $primary;
          background-color: $primary;
          color: $black;
          img {
            filter: invert(0);
          }
        }
      }
    }
  }
}

.dashboard-info {
  h2 {
    font-size: 22px;
    line-height: 41px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 40px;
    text-align: center;
  }
  .data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 27px;
    h3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 23px;
      font-size: 40px;
      line-height: 41px;
      font-weight: bold;
      span {
        display: block;
        font-size: 22px;
        line-height: 41px;
        font-weight: normal;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    .data {
      h3 {
        color: $primary;
        span {
          color: $black;
        }
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  .btn-outline {
    margin-top: 40%;
  }
}

// recent transactions
.recent-transactions {
  background-color: #ffffff;
  padding-top: 32px;
  border-radius: 32px 32px 0 0;
  margin-top: 48px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 25px 20px 25px;
    label {
      font-size: 22px;
      line-height: 41px;
      font-weight: bold;
    }
    a {
      font-size: 16px;
      font-weight: 600;
      line-height: 41px;
      cursor: pointer;
      color: black;
      text-decoration: none;
      img {
        width: 12px;
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 44px;
      cursor: pointer;
      color: black;
      text-decoration: none;
      img {
        width: 12px;
        margin-left: 8px;
        transform: rotate(180deg);
      }
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
  &--body {
    text-align: center;
    min-height: 400px;
    .btn-outline.icon-right-btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        transform: rotate(180deg);
      }
    }
    .btn-outline.icon-right-btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        transform: rotate(180deg);
      }
    }
    .react-loading-skeleton {
      max-width: calc(100% - 30px);
    }
  }
  @media only screen and (min-width: 1024px) {
    border: 2px solid #eeeeee;
    max-width: 600px;
    border-radius: 16px;
    &--header {
      padding: 0 20px 20px 16px;
    }
    &--body {
      padding-bottom: 10px;
      .btn-outline.icon-right-btn {
        display: none;
      }
    }
  }
}

// transaction item styles
.transaction-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 8px 16px;
  .item-image {
    background-color: #ffffff;
    height: 0;
    padding-top: 67px;
    width: 67px;
    min-width: 67px;
    position: relative;
    margin-right: 20px;
    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    .date {
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 4px;
    }
    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
    .type {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
  }
  .weight {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  .share-icon {
    filter: invert(50%);
    position: absolute;
    right: 18px;
    bottom: 14px;
    height: 17px;
    &:hover {
      filter: invert(71%) sepia(70%) saturate(3747%) hue-rotate(127deg) brightness(105%) contrast(101%);
    }
  }
  &.selected {
    background-color: rgba(188, 255, 42, 0.3);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
    cursor: pointer;
  }
}
.transaction-item-wrapper {
  padding-bottom: 20px;
  font-weight: 600;
  .transaction-date {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    line-height: 21px;
    margin-left: 16px;
    margin-bottom: 5px;
  }
}

//loader styling todo
.loader {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
}

.loader-logo {
  height: 10vh;
}
//loader styling todo

//claim todo
.claim {
  background: none;
  color: #000;
}

.claim button {
  width: 100%;
  margin-top: 10px;
}
//claim todo

//profile todo
.profile-card {
  background-color: $primary;
  border-radius: 8px;
  border-color: $primary;
  .card-body {
    padding: 20px 27px;
    position: relative;
    display: flex;
    flex-direction: column;
    .card-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-text {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 3px;
      &.joined {
        margin-bottom: 9px;
      }
      b {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        margin-right: 23px;
        min-width: 70px;
        display: inline-block;
      }
    }
    .icon-left-btn {
      position: absolute;
      bottom: 8px;
      right: 10px;
    }
  }
}
.newsletter-offers {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 61px;
  padding-bottom: 24px;
  .text {
    display: flex;
    flex-direction: column;
    label {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 2px;
    }
    span {
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.4);
      max-width: 180px;
    }
  }
}

.btn-full {
  padding: 13px 8px 13px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  margin-bottom: 20px;
  transition: ease 0.1s;
  background-color: transparent;
  border: none;
  width: 100%;
  &:hover {
    color: black;
    background-color: rgba(0, 0, 0, 0.01);
    padding-left: 8px;
  }
  span {
    font-size: 18px;
    line-height: 20px;
  }
  img {
    height: 14px;
  }
}

.c2zero-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  padding-top: 15px;
  a {
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: ease 0.3s;
    padding: 10px 24px 10px 0;
    img {
      height: 20px !important;
    }
    &:hover {
      transform: translateX(-4px);
    }
  }
  span {
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: ease 0.3s;
    img {
      height: 20px !important;
    }
    &:hover {
      transform: translateX(-4px);
    }
  }
  h1 {
    margin: 0;
    font-size: 34px;
    line-height: 41px;
    font-weight: 900;
  }
}

//share modal
.share-modal {
  .modal-close-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    img {
      height: 24px;
    }
  }
  .modal-body {
    .logo {
      height: 34px;
      margin: 0 auto;
      display: block;
      margin-top: 29px;
      margin-bottom: 34px;
    }
    .dashboard-info {
      .data {
        h3 {
          font-size: 30px;
          line-height: 31px;
          span {
            font-size: 18px;
            line-height: 31px;
          }
        }
      }
    }
    .share-btns {
      text-align: center;

      h3 {
        font-size: 22px;
        line-height: 41px;
        font-weight: bold;
      }
      .share-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
      }
      .facebook-btn {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ebebeb !important;
        margin: 0 23px;
        position: relative;
        &:hover {
          background-color: #d0cdcd !important;
        }
        img {
          height: 24px;
        }
        &::after {
          content: 'Facebook';
          position: absolute;
          bottom: -25px;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .twitter-btn {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ebebeb !important;
        margin: 0 23px;
        position: relative;
        &:hover {
          background-color: #d0cdcd !important;
        }
        img {
          height: 19.5px;
        }
        &::after {
          content: 'Twitter';
          position: absolute;
          bottom: -25px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  &.share-individual {
    .logo {
      height: 87px;
      margin-bottom: 5px;
      border: 1px solid #46546540;
    }
    .dashboard-info {
      h1 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 0;
        line-height: 41px;
        font-weight: 900;
        span {
          font-size: 20px;
          line-height: 41px;
          font-weight: 600;
        }
      }
      h2 {
        margin-top: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .data {
        flex-direction: column;
        .data-item {
          width: 100%;
          max-width: 300px;
          margin: 5px auto;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          span {
            font-size: 20px;
            line-height: 28px;
            font-weight: normal;
            text-align: right;
            padding-left: 5px;
          }
          .item-name {
            color: #708098;
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// page loader styles
.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  img {
    height: 200px;
  }
}

.empty-dashboard-msg {
  text-align: center;
  padding: 20px;
  img {
    height: 150px;
  }
  h2 {
    font-weight: 900;
    margin: 20px 0;
  }
  p {
    max-width: 500px;
    font-size: 16px;
    margin: 0 auto 50px auto;
  }
  .btn-default {
    margin-bottom: 50px;
  }
}

.floating-msg {
  position: fixed;
  bottom: 50px;
  left: calc(50% - 140px);
  right: calc(50% - 140px);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
  transition: ease 0.5s;
  &.show {
    display: inline-flex;
  }
  img {
    height: 20px;

    padding-left: 10px;
    cursor: pointer;
  }
  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
    padding-right: 10px;
    border-right: 1px solid rgb(0, 0, 0, 0.19);
  }
  &.success {
    background-color: $primary;
    color: $black;
  }
  &.error {
    background-color: #ea6673;
  }
}

// locking loader
.locking-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.2rem;
  }
  padding-bottom: 50px;
  &.dark {
    p {
      font-weight: bold;
    }
    .lf-player-container {
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 4px;
      margin-bottom: 20px;
    }
  }
}
// css loader
.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align: middle;
  border-radius: 100%;

  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loader1 0.8s linear infinite alternate;
  animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 255, 190, 0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 255, 190, 0.8);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(0, 255, 190, 1);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(0, 255, 190, 0.8);
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(0, 255, 190, 0.4);
}

@keyframes loader1 {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes loader1 {
  from {
    -webkit-transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
  }
}

//account delete notification banner
.account-delete-notification {
  min-height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background-color: red;
  color: $white;
  font-size: 11px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  .countdown {
    font-weight: bold;
    padding: 0 5px;
  }
  a {
    margin: 5px 0px 5px 10px;
    color: red;
    padding: 4px 10px;
    border-radius: 3px;
    background: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.page-layout.delete-nav {
  padding-top: 44px;
  .home-page-dashboard {
  }
  .home-nav {
    top: 44px;
  }
}
